import { isString } from "lodash";
var NativeUtils = /** @class */ (function () {
    function NativeUtils() {
    }
    NativeUtils.parseJSON = function (data) {
        if (data === void 0) { data = null; }
        if (data == null) {
            return null;
        }
        if (isString(data) && (data.startsWith("{") || data.startsWith("["))) {
            return JSON.parse(data.replace(/\r/g, "\\r").replace(/\n/g, "\\n"));
        }
        return data;
    };
    NativeUtils.createResponse = function (data) {
        if (data === void 0) { data = null; }
        return {
            isSuccess: true,
            statusCode: 1,
            data: this.parseJSON(data),
        };
    };
    NativeUtils.createFailedResponse = function () {
        return {
            isSuccess: false,
            statusCode: 0,
            data: null,
        };
    };
    return NativeUtils;
}());
export { NativeUtils };
