import { isString } from "lodash";
import { NativeReactor } from "./native-reactor";
import { NativeUtils } from "./native-utils";
var NativeBridgeCallback = /** @class */ (function () {
    function NativeBridgeCallback() {
        this.reactor = new NativeReactor();
        this.basic = {};
        this.biometric = {};
        this.keyboard = {};
        this.push = {};
        this.initBasicDomain();
        this.initBiometricDomain();
        this.initKeyboardDomain();
        this.initPushDomain();
    }
    NativeBridgeCallback.prototype.initBasicDomain = function () {
        this.reactor.registerEvent("native.basic.getAvailableMethods");
        this.reactor.registerEvent("native.basic.getAppInfo");
        this.reactor.registerEvent("native.basic.appIsReady");
        this.reactor.registerEvent("native.basic.onAppResume");
        this.reactor.registerEvent("native.basic.onAppPause");
        this.basic["getAvailableMethods"] = this.createDefaultFunction("native.basic.getAvailableMethods", true);
        this.basic["getAppInfo"] = this.createDefaultFunction("native.basic.getAppInfo", true);
        this.basic["onAppResume"] = this.createDefaultFunction("native.basic.onAppResume", true);
        this.basic["onAppPause"] = this.createDefaultFunction("native.basic.onAppPause", true);
    };
    NativeBridgeCallback.prototype.initBiometricDomain = function () {
        this.reactor.registerEvent("native.biometric.getStatus");
        this.reactor.registerEvent("native.biometric.getPin");
        this.reactor.registerEvent("native.biometric.savePin");
        this.reactor.registerEvent("native.biometric.clearPin");
        this.biometric["getStatus"] = this.createDefaultFunction("native.biometric.getStatus", true);
        this.biometric["getPin"] = this.createDefaultFunction("native.biometric.getPin", true);
        this.biometric["savePin"] = this.createDefaultFunction("native.biometric.savePin", true);
        this.biometric["clearPin"] = this.createDefaultFunction("native.biometric.clearPin", true);
    };
    NativeBridgeCallback.prototype.initKeyboardDomain = function () {
        this.reactor.registerEvent("native.keyboard.onWillHide");
        this.reactor.registerEvent("native.keyboard.onDidHide");
        this.reactor.registerEvent("native.keyboard.onWillShow");
        this.reactor.registerEvent("native.keyboard.onDidShow");
        this.keyboard["onWillHide"] = this.createDefaultFunction("native.keyboard.onWillHide", false);
        this.keyboard["onDidHide"] = this.createDefaultFunction("native.keyboard.onDidHide", false);
        this.keyboard["onWillShow"] = this.createDefaultFunction("native.keyboard.onWillShow", false);
        this.keyboard["onDidShow"] = this.createDefaultFunction("native.keyboard.onDidShow", false);
    };
    NativeBridgeCallback.prototype.initPushDomain = function () {
        var _this = this;
        this.reactor.registerEvent("native.push.newPushToken");
        this.reactor.registerEvent("native.push.getToken");
        this.reactor.registerEvent("native.push.messageRecieved");
        this.push["messageRecieved"] = this.createDefaultFunction("native.push.messageRecieved", true);
        this.push["getToken"] = function (data) {
            var dataParsed = NativeUtils.parseJSON(data);
            if (isString(dataParsed)) {
                // Convert to new model if data is still a string result
                dataParsed = {
                    token: dataParsed,
                    firebaseProjectId: null,
                };
            }
            _this.reactor.dispatchEvent("native.push.getToken", dataParsed);
        };
        this.push["newPushToken"] = function (data) {
            var dataParsed = NativeUtils.parseJSON(data);
            if (isString(dataParsed)) {
                // Convert to new model if data is still a string result
                dataParsed = {
                    token: dataParsed,
                    firebaseProjectId: null,
                };
            }
            _this.reactor.dispatchEvent("native.push.newPushToken", dataParsed);
        };
    };
    NativeBridgeCallback.prototype.createDefaultFunction = function (functionName, hasData) {
        var _this = this;
        if (hasData) {
            return function (data) {
                _this.reactor.dispatchEvent(functionName, NativeUtils.parseJSON(data));
            };
        }
        return function () {
            _this.reactor.dispatchEvent(functionName, null);
        };
    };
    return NativeBridgeCallback;
}());
export { NativeBridgeCallback };
